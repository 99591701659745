
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"

const formatTools = require("../../../lib/formatTools")
const webappsAPI = require("../../../lib/requestWebapps");


const ENTITYID="quotationprovider"

const quotationprovidertypecombolist = [
	{"value": 1, "display": "Others"},
	{"value": 2, "display": "Bus Shipping"},
	{"value": 3, "display": "Delivery"},
]

const searchFields = [
	{"label":"Type", "dbfield": "quotationprovidertype_id", "type": "combo", "filtertype": "combo", "options": quotationprovidertypecombolist},
	{"label":"Company Name", "dbfield": "quotationprovider_name", "type": "text", "filtertype": "text"},
	{"label":"Short Name", "dbfield": "quotationprovider_shortname", "type": "text", "filtertype": "text"},
	{"label":"Services", "dbfield": "quotationprovider_services", "type": "text", "filtertype": "text"},
	{"label":"Notes", "dbfield": "quotationprovider_notes", "type": "text", "filtertype": "text"},
	{"label":"Active", "dbfield": "quotationprovider_active", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Last Updated", "dbfield": "quotationprovider_updated", "type": "datetime", "filtertype": "date"}
];


const formFields = [
	{"label":"Type", "field": "quotationprovidertype_id", "value": "", "input": "combo", "mode": "required", "options": quotationprovidertypecombolist},
	{"label":"Name", "field": "quotationprovider_shortname", "value": "", "input": "text", "mode": "required", "info": "Short name"},
	{"label":"Services", "field": "quotationprovider_services", "value": "", "input": "textarea", "mode": "required"},
	{"label":"Company Name", "field": "quotationprovider_name", "value": "", "input": "text", "mode": "unique", "info": "Official name, used in legal documents such as payments"},
	{"label":"Company Address", "field": "quotationprovider_fulladdress", "value": "", "input": "textarea", "mode": "required", "info":"Please provide complete address"},
	{"label":"Active", "field": "quotationprovider_active", "value": "", "input": "checkbox", "mode": "normal"},
	{"label":"Last Updated", "field": "quotationprovider_updated", "value": "", "input": "datetime", "mode": "readonly"},
	{"label":"Notes", "field": "quotationprovider_notes", "value": "", "input": "textbox", "mode": "normal"},

	{"label":"Default Arrangement", "field": "quotationprovider_arrangement", "value": "", "input": "textarea", "mode": "required", "info":"e.g. Pick up point or drop off point"},

	{"label":"Default Contact", "field": "quotationprovider_contact", "value": "", "input": "textarea", "mode": "required", "info":"Contact person for transactions"},

];

const subformFields = [
	{
		"subformid": "quotationprovidercontact",
		"label": "Other Contacts",
		"table": "quotationprovidercontact",
		"sort": "quotationprovidercontact_name",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 100,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Name of Person",
				"field": "quotationprovidercontact_name",
				"subtotalid": "",
				"value": "",
				"mode": "required",
				"input": "textbox"
			},
			{
				"label": "Contact Information",
				"field": "quotationprovidercontact_info",
				"subtotalid": "",
				"value": "",
				"mode": "required",
				"input": "textarea"
			},
			{
				"label": "Notes/Role/Purpose",
				"field": "quotationprovidercontact_notes",
				"subtotalid": "",
				"value": "",
				"mode": "normal",
				"input": "textbox"
			}
		],
		"footerlist": [
		]
	}
];




const QuotationproviderPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [documentid, setDocumentid] = useState(0);


	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;
		//var customparam = {};
		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}
		params.quotationprovider_updated = formatTools.getDateStr();

		//callback({"status":"Error"}); return;

		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, callback);
	}

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				location={location}
				pagetitle={pagetitle}
				allowadd={true}
				allowdelete={false}
				entity={ENTITYID}
				mobilerowtitlefield={["quotationprovider_name"]}
				searchFields={searchFields}
				editFields={[formFields]}
				editSubFormFields={subformFields}

				customSubmit={customSubmit}

				token={token} />
		</Layout>

}


export default QuotationproviderPage;
